<template>
  <AppCard class="mb-3">
    <div class="p-3">
      <div class="d-flex align-items-center">
        <h4>
          {{ total }} {{ vendor ? 'VENDORS / SUPPLIER' : 'BUYER / SUPPLIER' }}
        </h4>
        <h5 v-if="newUsers > 0" class="text-success">+ {{ newUsers }} new</h5>
      </div>
      <div class="d-flex">
        <div
          v-for="(company, index) in companies.slice(0, 7)"
          :key="`user-${index}`"
          class="mr-n2"
        >
          <div
            class="
              rounded-circle
              overflow-hidden
              border border-white
              position-relative
            "
          >
            <AppMediaViewer
              width="40px"
              aspectRatio="1/1"
              class=""
              :src="company.logo"
            ></AppMediaViewer>
            <div
              v-if="index + 1 === 7"
              class="
                user-label
                text-white
                h-100
                w-100
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <span class="text-caption-2">{{ total - index }}+</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppCard>
</template>

<script>
import AppCard from '@/shared/elements/AppCard.vue';
import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';
export default {
  components: { AppCard, AppMediaViewer },
  name: 'AnalyticsUsersCard',

  props: {
    companies: { default: [] },
    total: { default: 0 },
    newUsers: { default: 0 },
    vendor: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.user-label {
  position: absolute;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
</style>
