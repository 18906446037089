import axios from 'axios';
import { URL_PREFIX } from './variables';

export const getTotalAmountTransactions = (...rest) =>
  axios.get(`${URL_PREFIX}/analytics/transactions/total`, ...rest);

export const getCompletedOrders = (...rest) =>
  axios.get(`${URL_PREFIX}/analytics/orders/total`, ...rest);

export const getMonthlyTransactions = (...rest) =>
  axios.get(`${URL_PREFIX}/analytics/transactions/monthly`, ...rest);

export const getMonthlySessions = (...rest) =>
  axios.get(`${URL_PREFIX}/analytics/users/total`, ...rest);

export default {
  getTotalAmountTransactions,
  getCompletedOrders,
  getMonthlyTransactions,
  getMonthlySessions,
};
