<template>
  <LayoutSuperadmin>
    <template v-slot:title>Analytics</template>

    <div class="p-3 p-md-5">
      <div class="row">
        <div class="col-12 col-md-8">
          <!-- Overview Section -->
          <div>
            <h4 class="header">OVERVIEW</h4>
            <div class="p-md-3">
              <div class="mt-2 row">
                <div class="col-12 col-md-4">
                  <AppCard class="h-100">
                    <div class="analytics-card p-3 h-100 d-flex flex-column">
                      <div class="header text-gray d-flex align-items-center">
                        <AppIcon name="currency_dollar"></AppIcon>
                        <span>TOTAL AMOUNT OF TRANSACTIONS</span>
                      </div>
                      <div class="value mt-auto d-flex align-items-center">
                        <span>
                          $ {{ overview.total_amount_transactions.value }}
                        </span>
                        <span
                          class="ml-1 percent"
                          :class="{
                            'text-success':
                              overview.total_amount_transactions.percentage >=
                              0,
                            'text-danger':
                              overview.total_amount_transactions.percentage < 0,
                          }"
                        >
                          {{
                            overview.total_amount_transactions.percentage >= 0
                              ? '+'
                              : '-'
                          }}
                          {{
                            Math.abs(
                              overview.total_amount_transactions.percentage
                            )
                          }}%
                        </span>
                      </div>
                    </div>
                  </AppCard>
                </div>
                <div class="col-12 col-md-4">
                  <AppCard class="h-100">
                    <div class="analytics-card p-3 h-100 d-flex flex-column">
                      <div class="header text-gray d-flex align-items-center">
                        <AppIcon name="tag"></AppIcon>
                        <span>COMPLETED ORDERS</span>
                      </div>
                      <div class="value mt-auto d-flex align-items-center">
                        <span>{{ overview.completed_orders.value }}</span>
                        <span
                          class="ml-1 percent"
                          :class="{
                            'text-success':
                              overview.completed_orders.percentage >= 0,
                            'text-danger':
                              overview.completed_orders.percentage < 0,
                          }"
                        >
                          {{
                            overview.completed_orders.percentage >= 0
                              ? '+'
                              : '-'
                          }}
                          {{ Math.abs(overview.completed_orders.percentage) }}%
                        </span>
                      </div>
                    </div>
                  </AppCard>
                </div>

                <div class="col-12 col-md-4">
                  <AppCard class="h-100">
                    <div class="analytics-card p-3 h-100 d-flex flex-column">
                      <div class="header text-gray d-flex align-items-center">
                        <AppIcon name="user"></AppIcon>
                        <span>TOTAL NUMBER OF USERS</span>
                      </div>
                      <div class="value mt-auto d-flex align-items-center">
                        <span>{{ users.length }}</span>
                        <span class="percent ml-3 text-success">
                          {{
                            users.filter(
                              (user) => 1 > timePassedCal(user.created_at).day
                            ).length
                          }}
                          NEW
                        </span>
                      </div>
                    </div>
                  </AppCard>
                </div>
              </div>
            </div>
          </div>
          <!-- Sales Stats -->
          <div>
            <div class="d-flex">
              <h4 class="header">SALE ANALYTICS</h4>
              <!-- Buttons to be placed here -->
            </div>
            <LineChart
              v-if="datacollection.datasets[0].data.length > 0"
              :chart-data="datacollection"
              :height="chartHeight"
            ></LineChart>
          </div>

          <!-- Sales Stats -->
          <div class="mt-5">
            <div class="d-flex">
              <h4 class="header">SESSIONS</h4>
              <!-- Buttons to be placed here -->
            </div>
            <BarChart
              v-if="datacollectionBar.datasets[0].data.length > 0 || datacollectionBar.datasets[1].data.length > 0"
              :chart-data="datacollectionBar"
              :height="chartHeight"
            ></BarChart>
          </div>

          <!-- Top Seller -->
          <!-- <div class="mt-5">
            <h4>TOP SELLERS</h4>

            <AppTable
              :headers="[
                { text: 'Vendor', sortable: false },
                { text: 'Sent Quotes', sortable: false },
                { text: 'Completed Orders', sortable: false },
                { text: 'Total Sales', sortable: false },
              ]"
            ></AppTable>
          </div> -->
        </div>

        <div class="col-12 mt-5 mt-md-0 col-md-4">
          <!-- Users -->
          <div>
            <AnalyticsUsersCard
              v-if="companies.vendor"
              vendor
              class="mb-3"
              :total="companies.vendor.total"
              :companies="companies.vendor.data"
            ></AnalyticsUsersCard>

            <AnalyticsUsersCard
              v-if="companies.buyer"
              class="mb-3"
              :total="companies.buyer.total"
              :companies="companies.buyer.data"
            ></AnalyticsUsersCard>
          </div>

          <!-- Recent Events -->
          <AppCard>
            <div class="p-2">
              <div class="d-flex justify-content-between">
                <h4>RECENT EVENTS</h4>
                <AppBtn
                  text
                  color="info"
                  @click="$router.push({ name: 'NotificationList' })"
                >
                  See All
                </AppBtn>
              </div>
              <!-- Notifications -->
              <div v-if="notifications" class="p-3">
                <div
                  v-for="(notif, index) in notifications"
                  :key="`notif-${index}`"
                  class="mb-2"
                >
                  <p class="m-0" v-html="notif.message"></p>
                  <label class="text-gray text-caption">
                    {{ timePassedCal(notif.rawDate).timePassedString }}
                  </label>
                </div>
              </div>
            </div>
          </AppCard>
        </div>
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
/* eslint-disable */
import LayoutSuperadmin from '@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import AppCard from '@/shared/elements/AppCard.vue';
import AppIcon from '../../../shared/elements/AppIcon.vue';
import { LineChart, BarChart } from '@/shared/plugins/vue-chart';
import timePassedCal from '../../../shared/utils/timePassedCalculator';
import { DateTime } from 'luxon';
import AnalyticsUsersCard from '../../../components/Partial/Analytics/AnalyticsUsersCard.vue';
import AppBtn from '../../../shared/elements/AppBtn.vue';
import AppTable from '../../../shared/elements/AppTable.vue';
import Company from '@/shared/api/Company';
import Notifications from '@/shared/api/Notifications';
import Users from '@/shared/api/Users';
import Analytics from '@/shared/api/Analytics';

export default {
  name: 'SuperAnalytics',

  components: {
    LayoutSuperadmin,
    AppCard,
    AppIcon,
    AnalyticsUsersCard,
    AppBtn,
    LineChart,
    BarChart,
    AppTable,
  },

  data() {
    return {
      datacollection: {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'June',
          'July',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Sales',
            borderColor: '#f87979',
            backgroundColor: '#ffc9c9',
            data: [],
          },
        ],
      },
      datacollectionBar: {
        labels: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'June',
          'July',
          'Aug',
          'Sept',
          'Oct',
          'Nov',
          'Dec',
        ],
        datasets: [
          {
            label: 'Vendor',
            backgroundColor: 'blue',
            maxBarThickness: 20,
            data: [],
          },
          {
            label: 'Buyer',
            backgroundColor: 'purple',
            maxBarThickness: 20,
            data: [],
          },
        ],
      },
      notifications: [],
      users: [],
      overview: {
        total_amount_transactions: {
          value: 0,
          percentage: 0,
        },
        completed_orders: {
          value: 0,
          percentage: 0,
        },
      },
      companies: {
        vendor: null,
        buyer: null,
      },

      windowWidth: window.innerWidth,
    };
  },
  methods: {
    timePassedCal,
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    async getUsers() {
      const response = await Users.getUsers();

      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    },
    async getCompanies(persona = null) {
      let params = {};
      if (persona === 'vendor') {
        params.vendor_only = 1;
      } else if (persona === 'buyer') {
        params.buyer_only = 1;
      }

      const response = await Company.getCompanies({ params });

      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    },
    async getNotification() {
      const response = await Notifications.getNotifications({
        params: { per_page: 15 },
      });

      if (response.status === 200) {
        return response.data.data.map((notif) => ({
          ...notif,
          rawDate: DateTime.fromISO(notif.created_at).toFormat(
            'yyyy-MM-dd HH:mm:ss'
          ),
        }));
      } else {
        return null;
      }
    },
    async getTotalAmountTransactions() {
      const { data } = await Analytics.getTotalAmountTransactions();

      let percentage = 0;

      if (data.last_month <= 0) {
        percentage = data.total;
      } else {
        percentage = ((data.total - data.last_month) / data.last_month) * 100;
        percentage = parseFloat(percentage.toFixed(2));
      }

      const val = {
        value: data.total,
        percentage: percentage,
      };

      return val;
    },
    async getCompletedOrders() {
      const { data } = await Analytics.getCompletedOrders();

      let percentage = 0;

      if (data.last_month <= 0) {
        percentage = data.total;
      } else {
        percentage = ((data.total - data.last_month) / data.last_month) * 100;
        percentage = parseFloat(percentage.toFixed(2));
      }

      const val = {
        value: data.total,
        percentage: percentage,
      };

      return val;
    },
    async getMonthlyTransactions() {
      const { data } = await Analytics.getMonthlyTransactions();

      return data;
    },
    async getMonthlySessions() {
      const { data } = await Analytics.getMonthlySessions();

      return data;
    },
    async initializeData() {
      this.users = await this.getUsers();
      this.companies.vendor = await this.getCompanies('vendor');
      this.companies.buyer = await this.getCompanies('buyer');
      this.notifications = await this.getNotification();

      const total_amount_transactions = await this.getTotalAmountTransactions();
      const completed_orders = await this.getCompletedOrders();

      this.overview = {
        total_amount_transactions: {
          value: total_amount_transactions.value,
          percentage: total_amount_transactions.percentage,
        },
        completed_orders: {
          value: completed_orders.value,
          percentage: completed_orders.percentage,
        },
      };

      const monthly = await this.getMonthlyTransactions();
      this.datacollection.labels = Object.keys(monthly);
      this.datacollection.datasets[0].data = Object.values(monthly);

      const sessions = await this.getMonthlySessions();
      this.datacollectionBar.labels = Object.keys(sessions);
      this.datacollectionBar.datasets[0].data = Object.keys(sessions).map(
        (key) => {
          return sessions[key].vendor;
        }
      );

      this.datacollectionBar.datasets[1].data = Object.keys(sessions).map(
        (key) => {
          return sessions[key].buyer;
        }
      );
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  computed: {
    chartHeight() {
      if (this.windowWidth >= 1440) {
        return 200;
      }

      return 500;
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  mounted() {
    this.initializeData();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
};
</script>

<style lang="scss" scoped>
.header {
  font-size: 1.6rem;
}

.analytics-card {
  .header {
    font-size: 12px;
  }

  .value {
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.8px;

    .percent {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.8px;
    }
  }
}
</style>
